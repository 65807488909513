import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "isNewMe max-width acea-row row-between"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "me-menu"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "rit",
  id: "newAppRit"
};
import { ref, watch } from "vue";
import { useRoute } from 'vue-router';
import { router } from "@/router";
import Me from "@/view/me.vue";
import Card from "@/view/card.vue";
import Level from "@/view/level.vue";
import Auth from "@/view/auth.vue";
import historyBill from "@/view/historyBill.vue";
import contractOrder from "@/view/contractOrder.vue";
import liquidityOrder from "@/view/liquidityOrder.vue";
import Team from "@/view/team.vue";
import changePwd from "@/view/changePwd.vue";
export default {
  __name: 'MeNew',
  setup(__props) {
    const route = useRoute();
    const setLists = ref([{
      name: 'personal',
      img: require('@/assets/images/my.png'),
      index: 0
    },
    // {name:'meCard',img: require('@/assets/images/card.png'),index:1},
    {
      name: 'newMe.TopUp',
      img: require('@/assets/images/toUp.png'),
      index: 2
    }, {
      name: 'real_name_authentication',
      img: require('@/assets/images/auth.png'),
      index: 3
    }, {
      name: 'billing_history',
      img: require('@/assets/images/history.png'),
      index: 4
    }, {
      name: 'second_contract_order',
      img: require('@/assets/images/contract_order.png'),
      index: 5
    }, {
      name: 'liquidity_pool_order',
      img: require('@/assets/images/staking_order.png'),
      index: 6
    },
    // {name:'my_team',img: require('@/assets/images/team.png'),index:7},
    {
      name: 'change_password',
      img: require('@/assets/images/pwd.png'),
      index: 8
    }]);
    let indexs = route.query.type ? parseFloat(route.query.type) : 0;
    const active = ref(indexs);
    watch(route, newRoute => {
      console.log(newRoute);
      active.value = parseFloat(route.query.type);
    });
    const setIndex = item => {
      active.value = item.index;
      router.push(`/me?type=${item.index}`);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(setLists.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["me-menu__item", {
            'active': active.value === item.index
          }]),
          onClick: $event => setIndex(item),
          key: index
        }, [_createElementVNode("div", null, [_createElementVNode("img", {
          src: item.img,
          alt: ""
        }, null, 8, _hoisted_5), _createTextVNode(_toDisplayString(_ctx.$t(item.name)), 1)])], 10, _hoisted_4);
      }), 128))])]), _createElementVNode("div", _hoisted_6, [active.value === 0 ? (_openBlock(), _createBlock(Me, {
        key: 0
      })) : _createCommentVNode("", true), active.value === 1 ? (_openBlock(), _createBlock(Card, {
        key: 1
      })) : _createCommentVNode("", true), active.value === 2 ? (_openBlock(), _createBlock(Level, {
        key: 2
      })) : _createCommentVNode("", true), active.value === 3 ? (_openBlock(), _createBlock(Auth, {
        key: 3
      })) : _createCommentVNode("", true), active.value === 4 ? (_openBlock(), _createBlock(historyBill, {
        key: 4
      })) : _createCommentVNode("", true), active.value === 5 ? (_openBlock(), _createBlock(contractOrder, {
        key: 5
      })) : _createCommentVNode("", true), active.value === 6 ? (_openBlock(), _createBlock(liquidityOrder, {
        key: 6
      })) : _createCommentVNode("", true), active.value === 8 ? (_openBlock(), _createBlock(changePwd, {
        key: 7
      })) : _createCommentVNode("", true)])]);
    };
  }
};